import Config from './src/interfaces/Config';

const config: Config = {
  siteTitle: 'Toposware Developer Community',
  twitterName: '@toposware',
  title: 'Toposware Developer Community',
  rootUrl: '/content/', // used only for the social card atm, required (update when using other environment)
  protectedRoutePrefix: '/ambassadors', // slash at the beginning, but not at the end. Also the page file name has to match.
  includeFolder: ['features-test', 'content', 'legal'],
  indexPath: 'content', // could be content/module-1/introduction.html for example or any path to a html page
  includeProtectedFolder: ['ambassadors'],
  protectedPagesPath: 'src/protected/pages',
  protectedLayoutsPath: 'src/protected/layout',
  pageTransitionDuration: 0.2,
  enableH3Toc: true,
  headerOffset: 100,
  docSearch: {
    appId: 'S36OISFKTX',
    apiKey: 'fc77ad501873a0a81b4c39e02a4aaaac',
    indexName: 'crawler_Toposware docs platform (new)',
    placeholder: 'Search documentation',
  },
  navigation: [
    {
      label: 'Newsletter',
      content: [
        {
         label:  '02 January 2024',
         path: '/content/02January2024.html',
        },
      ],
    },
  ],
  footerLinks: [
    {
      label: 'Privacy Policy',
      path: '/legal/privacy-policy.html',
    },
  ],
  storage: {
    cookieConsentKey: 'cookieConsent',
  },
};

export default config;
