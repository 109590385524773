exports.components = {
  "component---plugins-gatsby-platform-module-src-pages-protected-tsx": () => import("./../../../plugins/gatsby-platform-module/src/pages/protected.tsx" /* webpackChunkName: "component---plugins-gatsby-platform-module-src-pages-protected-tsx" */),
  "component---src-components-layout-layout-tsx-content-file-path-content-02-january-2024-md": () => import("./../../../src/components/layout/Layout.tsx?__contentFilePath=/home/runner/work/community/community/content/02January2024.md" /* webpackChunkName: "component---src-components-layout-layout-tsx-content-file-path-content-02-january-2024-md" */),
  "component---src-components-layout-layout-tsx-content-file-path-content-index-md": () => import("./../../../src/components/layout/Layout.tsx?__contentFilePath=/home/runner/work/community/community/content/index.md" /* webpackChunkName: "component---src-components-layout-layout-tsx-content-file-path-content-index-md" */),
  "component---src-components-layout-layout-tsx-content-file-path-features-test-index-md": () => import("./../../../src/components/layout/Layout.tsx?__contentFilePath=/home/runner/work/community/community/features-test/index.md" /* webpackChunkName: "component---src-components-layout-layout-tsx-content-file-path-features-test-index-md" */),
  "component---src-components-layout-layout-tsx-content-file-path-features-test-landingpage-md": () => import("./../../../src/components/layout/Layout.tsx?__contentFilePath=/home/runner/work/community/community/features-test/landingpage.md" /* webpackChunkName: "component---src-components-layout-layout-tsx-content-file-path-features-test-landingpage-md" */),
  "component---src-components-layout-layout-tsx-content-file-path-legal-privacy-policy-md": () => import("./../../../src/components/layout/Layout.tsx?__contentFilePath=/home/runner/work/community/community/legal/privacy-policy.md" /* webpackChunkName: "component---src-components-layout-layout-tsx-content-file-path-legal-privacy-policy-md" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-protected-pages-login-tsx": () => import("./../../../src/protected/pages/login.tsx" /* webpackChunkName: "component---src-protected-pages-login-tsx" */),
  "component---src-protected-pages-logout-tsx": () => import("./../../../src/protected/pages/logout.tsx" /* webpackChunkName: "component---src-protected-pages-logout-tsx" */),
  "component---src-protected-pages-profile-tsx": () => import("./../../../src/protected/pages/profile.tsx" /* webpackChunkName: "component---src-protected-pages-profile-tsx" */),
  "component---src-protected-pages-register-tsx": () => import("./../../../src/protected/pages/register.tsx" /* webpackChunkName: "component---src-protected-pages-register-tsx" */)
}

